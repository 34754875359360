<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAHome',
  metaInfo: { title: 'Home' },
  extends: View,

  mixins: [
    LoadSections([
      'KDAHomeHeader',
      'KDAHomeQuote',
      'KDAConsultCompanyLogo',
      'KDAHomeProcess',
      'KDAHomeSolution',
      'KDAHomeProject',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'home'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Home',
    })
  }, 
};
</script>
